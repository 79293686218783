import React from "react";
import styled from "styled-components";
import { colors } from "../../config/styles";

const Title = (props) => (
  <TitleContainer className={props.className || ""} margin={props.margin}>
    {props.text}
  </TitleContainer>
);

const TitleContainer = styled.h2`
  font-family: "Roboto", "sans-serif";
  color: ${colors.GRAY};
  font-size: 16px;
  margin: 6px 0 12px 0;
  padding-left: 10px;
  border-left: 5px solid ${colors.ACCENT};
  font-weight: bold;
  text-transform: uppercase;
  margin-top: ${(props) => (props.margin ? "24px" : "0px")};

  @media screen and (max-width: 992px) {
    margin-top: 24px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

export default Title;

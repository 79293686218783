import { Fragment } from "react";
import ListItem from "../Matches/ListItem";
import Link from "next/link";
import RankingWarning from "./RankingWarning";
import styled from "styled-components";
import { colors } from "../../config/styles";

const RankingValveBox = ({ data }) => {
  if (data.data.length <= 0) return <div>Nenhuma informação encontrada</div>;

  return (
    <Fragment>
      {data.data &&
        data.data.slice(0, 5).map((data, index) => {
          const first = index === 0;
          const second = index === 1;
          const third = index === 2;
          return (
            <Link
              prefetch={false}
              key={data.rankingValvePosition}
              href="/ranking"
              passHref
            >
              <ListItemContainer first={first} second={second} third={third}>
                #{data.rankingValvePosition} {data.rankingValveTeamName}
                <right>{data.rankingValvePoints} pts</right>
              </ListItemContainer>
            </Link>
          );
        })}
      <Link prefetch={false} href="/ranking" passHref>
        <a>
          <ListItem small dark>
            Ver ranking da valve completo
            <RankingWarning small />
          </ListItem>
        </a>
      </Link>
      <br />
    </Fragment>
  );
};

const ListItemContainer = styled.div`
  display: flex;
  background: white;
  color: ${colors.GRAY};
  width: 100%;
  cursor: pointer;
  transition: all 0.1s;
  padding: 13px 17px;
  border-left: 2px solid transparent;

  right {
    margin-left: auto;
    font-size: 13px;
  }

  &:hover {
    background: ${colors.ACCENT};
    color: white;
    border-left: 2px solid ${colors.MAIN};
  }

  font-size: ${(props) =>
    props.first
      ? "17px"
      : props.second
      ? "16px"
      : props.third
      ? "15px"
      : "14px"};
`;

export default RankingValveBox;

import React from "react";
import styled from "styled-components";
import { colors } from "../../../config/styles";
import Button, { buttonSizes, buttonThemes } from "../../Shared/Button";
import LoadingIndicator from "../../Shared/LoadingIndicator";
import PopularNewsCard from "../PopularNewsCard";

const PopularNewsList = (props) => {
  return (
    <PopularNewsListContainer>
      {/* =================== */}
      {/* Error State */}
      {/* =================== */}
      {props.error && (
        <ErrorContainer>
          <span>Ocorreu um erro, por favor tente novamente</span>
          <Button
            onClick={props.onRetry}
            text="Tentar Novamente"
            theme={buttonThemes.default.MAIN}
            size={buttonSizes.SMALL}
          />
        </ErrorContainer>
      )}

      {/* =================== */}
      {/* Loading State */}
      {/* =================== */}
      {!props.error && props.isLoading && (
        <LoadingIndicator text="Carregando..." />
      )}

      {/* =================== */}
      {/* Empty State */}
      {/* =================== */}
      {!props.isLoading &&
        !props.error &&
        props.data.length === 0 &&
        "Nenhuma notícia encontrada"}

      {/* =================== */}
      {/* Normal State */}
      {/* =================== */}
      {!props.isLoading &&
        !props.error &&
        props.data.map((news) => (
          <PopularNewsCard
            key={`PopularNewsCard_${news.postSlug}`}
            title={news.postTitle}
            date={news.postDate}
            image={news.postImage}
            slug={news.postSlug}
          />
        ))}
    </PopularNewsListContainer>
  );
};

const PopularNewsListContainer = styled.div`
  > a {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

const ErrorContainer = styled.div`
  span {
    color: ${colors.ERROR};
    display: block;
    text-align: center;
    margin-bottom: 12px;
  }
`;

export default PopularNewsList;

import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../../config/styles";

export default class ListItem extends Component {
  render() {
    const {
      active,
      dark,
      small,
      center,
      onClick,
      className,
      label,
      captilaze,
    } = this.props;

    return (
      <ListItemContainer
        active={active}
        dark={dark}
        small={small}
        center={center}
        onClick={onClick}
        className={className}
        captilaze={captilaze}
      >
        <div>{this.props.children}</div>

        <ListItemLabel>
          {typeof label === "function" && label()}

          {typeof label !== "function" && label}
        </ListItemLabel>
      </ListItemContainer>
    );
  }
}

const ListItemContainer = styled.button`
  background: white;
  display: block;
  font-size: 13px;
  border-bottom: 1px solid #f0f0f0;
  padding: 13px 17px;
  color: ${colors.GRAY};
  transition: all 0.2s;
  border: 0;
  border-left: 3px solid transparent;
  border-bottom: 1px solid ${colors.GRAY_LIGHTEST};
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: ${colors.ACCENT};
    color: white;
    border-left: 3px solid ${colors.MAIN};
    border-bottom: 1px solid ${colors.ACCENT};
  }

  ${(props) =>
    props.captilaze &&
    `
    text-transform: capitalize;
  `}

  ${(props) =>
    props.dark &&
    `
    background: ${colors.MAIN};
    color: white;
  `}

  ${(props) =>
    props.active &&
    `
    background: ${colors.ACCENT};
    color: white;
    border-left: 3px solid ${colors.MAIN};
    border-bottom: 1px solid ${colors.ACCENT};
  `}

  ${(props) =>
    props.small &&
    `
    padding: 8px 10px;
    font-size: 10px;
  `}

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}

  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 2;
  }
`;

const ListItemLabel = styled.div`
  font-size: 11px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

import React from "react";
import Slider from "react-slick";

// css
import styled from "styled-components";
import { colors } from "../../config/styles";
import FeaturedMatchCard from "./FeaturedMatchCard";

const MatchCarousel = (props) => {
  const settings = {
    dots: false,
    infinite: false,
    draggable: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <MatchCarouselContainer>
      <Slider {...settings}>
        {props.data.map((match) => (
          <StyledFeaturedMatchCard
            onClick={props.onMatchClick}
            isSelected={
              props.selectedMatch && match.matchId === props.selectedMatch
            }
            match={match}
            key={match.matchId}
            odds={props.odds}
          />
        ))}
      </Slider>
    </MatchCarouselContainer>
  );
};

export default MatchCarousel;

const MatchCarouselContainer = styled.div`
  padding: 16px 32px 0px 32px;
  background: ${colors.MAIN};
  overflow: hidden;

  @media screen and (max-width: 768px) {
    max-height: none;
    padding: 32px 24px;
  }

  & .slick-slide > div {
    padding: 0 8px;
    padding-top: 2px;
  }

  & .slick-active:last-child > div {
    margin-right: 4px;
  }

  & .slick-arrow {
    margin-top: -10px;
  }
`;

const StyledFeaturedMatchCard = styled(FeaturedMatchCard)`
  margin-right: 16px;
`;

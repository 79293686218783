import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BetsService from "../../services/bets";
import LoadingIndicator from "../Shared/LoadingIndicator";

const GenericLiveOddsLeaderboard = ({ provider, limit, random }) => {
  const [betData, setBetData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const placeholderLogo =
    "https://static.draft5.gg/assets/team-logo-placeholder.png";

  useEffect(() => {
    trackWidgetImpression();

    BetsService.getLiveOddsWithGameKey(
      provider.PROVIDER_KEY,
      "draft5csgo"
    ).then(
      (response) => {
        {
          !random && setBetData(response.data.data.slice(0, limit));
        }
        {
          !!random &&
            setBetData(
              response.data.data.sort(() => Math.random() - 0.5).slice(0, limit)
            );
        }
        setIsLoading(false);
      },
      () => {
        setError(true);
        setIsLoading(false);
      }
    );
  }, []);

  const trackWidgetImpression = () => {
    window.dataLayer.push({
      event: "LiveOdds728Impression",
      liveOddsProvider: provider.PROVIDER_KEY,
    });
  };

  const trackWidgetClick = () => {
    window.dataLayer.push({
      event: "LiveOdds728Click",
      liveOddsProvider: provider.PROVIDER_KEY,
    });
  };

  return (
    <LiveOddsContainer provider={provider}>
      <ProviderLogo
        onClick={trackWidgetClick}
        href={provider.PROVIDER_URL}
        // href="https://mercuryeasy.com/gg/gb/?param=draft5_3&lp=00&go=sitereg&deeplink=/news/welcome-bonus-2023"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src={provider.PROVIDER_LOGO} />
      </ProviderLogo>

      {/* ========================== */}
      {/* LOADING ================== */}
      {/* ========================== */}
      {isLoading && <LoadingIndicator />}

      {/* ========================== */}
      {/* EMPTY STATE ============== */}
      {/* ========================== */}
      {!isLoading && betData.length === 0 && (
        <LiveOddMatch provider={provider}>
          <span>Nenhuma partida encontrada.</span>
        </LiveOddMatch>
      )}

      {/* ========================== */}
      {/* ERROR STATE ============== */}
      {/* ========================== */}
      {!isLoading && error && (
        <LiveOddMatch provider={provider}>
          <span>Ocorreu um erro, tente novamente.</span>
        </LiveOddMatch>
      )}

      {/* ========================== */}
      {/* NORMAL STATE============== */}
      {/* ========================== */}
      {!isLoading &&
        betData.length > 0 &&
        betData.map((match) => (
          <LiveOddMatch
            // href={match.teamA?.link}
            href={provider.PROVIDER_URL}
            target="_blank"
            rel="noopener noreferrer"
            provider={provider}
            key={`${match.teamA.name}-${match.teamB.name}-${match.matchId}`}
            onClick={() => {
              trackWidgetClick(
                `${match.matchId} - ${match.teamA.name} vs ${match.teamB.name}`
              );
            }}
          >
            <img
              src={`https://${match.teamA?.logo}`}
              width="30"
              alt={match.teamA.name}
              title={match.teamA.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = placeholderLogo;
              }}
            />
            {match.teamA.odd && match.teamB.odd && (
              <>
                <OddBtn
                  provider={provider}
                  // href={match.teamA?.link}
                  href={provider.PROVIDER_URL}
                  title={match.teamA.name}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => {
                    trackWidgetClick(
                      `${match.matchId} - ${match.teamA.name} vs ${match.teamB.name}`
                    );
                  }}
                >
                  {match.teamA.odd.toFixed(2)}
                </OddBtn>
                <span>vs</span>
                <OddBtn
                  provider={provider}
                  // href={match.teamB?.link}
                  href={provider.PROVIDER_URL}
                  title={match.teamB.name}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => {
                    trackWidgetClick(
                      `${match.matchId} - ${match.teamA.name} vs ${match.teamB.name}`
                    );
                  }}
                >
                  {match.teamB.odd.toFixed(2)}
                </OddBtn>
              </>
            )}

            {(!match.teamA?.odd || !match.teamB?.odd) && (
              <OddBtn
                block
                provider={provider}
                // href={match.teamA?.link}
                href={provider.PROVIDER_URL}
                target="blank"
                rel="noopener noreferrer"
              >
                ver odds
              </OddBtn>
            )}
            <img
              src={`https://${match.teamB.logo}`}
              width="30"
              alt={match.teamB.name}
              title={match.teamB.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = placeholderLogo;
              }}
            />
          </LiveOddMatch>
        ))}
      <br />
      <br />
      <Disclaimer provider={provider}>
        +18. Aposte com responsabilidade. Odds sujeitas a alteração.
      </Disclaimer>
    </LiveOddsContainer>
  );
};

const LiveOddsContainer = styled.div`
  background: ${(p) => p.provider.colors.BACKGROUND_DARKER};
  ${(p) =>
    p.provider.BACKGROUND_IMAGE
      ? `background-image: url("${p.provider.BACKGROUND_IMAGE}")`
      : ``};
  background-position: center center;
  background-repeat: no-repeat;
  color: ${(p) => p.provider.colors.TEXT};
  border-top: 3px solid ${(p) => p.provider.colors.ACCENT};
  border-bottom: 3px solid ${(p) => p.provider.colors.ACCENT};
  padding: 2px;
  display: flex;
  width: 728px;
  height: 90px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  margin: 0 auto;
  position: relative;

  img {
    content-visibility: auto;
  }
`;

const ProviderLogo = styled.a`
  display: flex;
  text-align: center;
  align-content: center;
  align-items: center;
  flex-basis: 25%;
  justify-content: center;

  img {
    width: 120px;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 140px;
    }
  }
`;

const LiveOddMatch = styled.a`
  background-color: ${(p) => p.provider.colors.BACKGROUND};

  display: flex;
  align-items: center;
  padding: 0 3px;
  transition: all 0.2s;
  flex-direction: row-reverse;

  &:hover {
    background-color: ${(p) => p.provider.colors.HOVER_COLOR};
  }

  span {
    color: ${(p) => p.provider.colors.ACCENT};
    font-size: 12px;
    padding: 0 3px;
  }

  img {
    max-width: 24px;
    max-height: 24px;
    margin: 0 5px;
  }

  @media screen and (max-width: 768px) {
    span {
      font-size: 16px;
    }
  }
`;

const OddBtn = styled.div`
  border-radius: 3px;
  margin: 0;
  cursor: pointer;
  color: ${(p) => p.provider.colors.BACKGROUND_DARKER};
  font-weight: 700;
  font-size: 13px;
  line-height: 26px;
  padding: 0 3px;

  height: 26px;
  max-width: 38px;
  min-width: 38px;
  text-align: center;
  transition: all 0.2s;

  background: linear-gradient(
        143deg,
        ${(p) => p.provider.colors.ACCENT} 90%,
        transparent 91%
      )
      100% 0/20px 100% no-repeat,
    linear-gradient(
        -37deg,
        ${(p) => p.provider.colors.ACCENT} 90%,
        transparent 91%
      )
      0 0/20px 100% no-repeat,
    linear-gradient(
        ${(p) => p.provider.colors.ACCENT},
        ${(p) => p.provider.colors.ACCENT}
      )
      20px 0 / calc(100% - 30px) 100% no-repeat;

  ${(p) =>
    p.block &&
    `
    width: 100%;
    max-width: 100%;
    flex: 1;
    margin: 0 12px;
  `}

  &:hover {
    background: linear-gradient(
          143deg,
          ${(p) => p.provider.colors.BACKGROUND_DARKER} 90%,
          transparent 91%
        )
        100% 0/20px 100% no-repeat,
      linear-gradient(
          -37deg,
          ${(p) => p.provider.colors.BACKGROUND_DARKER} 90%,
          transparent 91%
        )
        0 0/20px 100% no-repeat,
      linear-gradient(
          ${(p) => p.provider.colors.BACKGROUND_DARKER},
          ${(p) => p.provider.colors.BACKGROUND_DARKER}
        )
        20px 0 / calc(100% - 30px) 100% no-repeat;
    text-shadow: none;
    color: ${(p) => p.provider.colors.TEXT};
    transition: 0.1s;
  }

  @media screen and (max-width: 768px) {
    line-height: 28px;
    height: 28px;
    // max-width: 38px;
    // min-width: 38px;
    font-size: 16px;
  }
`;

const Disclaimer = styled.div`
  text-align: center;
  color: ${(p) => p.provider.colors.TEXT};
  font-size: 10px;
  flex-basis: 100%;
  padding: 2px 0;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export default GenericLiveOddsLeaderboard;

import React, { Component, useContext } from "react";
import styled from "styled-components";

// components
import Card from "./Card";
import Icon from "./Icon";

// services
import { colors } from "../../config/styles";
import { FeatureFlagContext } from "../../contexts/FeatureFlagContext";

const RankingWarning = (props) => {
  const small = props.small;
  const normal = props.normal;

  const context = useContext(FeatureFlagContext);

  const flagRankingWarning = context.getFlagValue("RANKING_WARNING");
  const flagRankingWarningSmall = context.getFlagValue("RANKING_WARNING_SMALL");

  return (
    <RankingWarninContainer>
      {flagRankingWarning && normal && (
        <TextCard normal={normal}>
          <IconWarning
            name="warning"
            size={24}
            fill={colors.ODDS_BETTER_MAIN}
          />
          {flagRankingWarning}
        </TextCard>
      )}
      {flagRankingWarningSmall && small && (
        <TextCard small={small}>
          <IconWarning
            small={small}
            name="warning"
            size={9}
            fill={colors.ODDS_BETTER_MAIN}
          />
          {flagRankingWarningSmall}
        </TextCard>
      )}
      <br />
    </RankingWarninContainer>
  );
};

const RankingWarninContainer = styled.div``;

const TextCard = styled(Card)`
  font-size: ${(props) => (props.small ? `10px` : `16px`)};
  padding: ${(props) => (props.small ? `0px` : `20px`)};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.small ? `${colors.ODDS_BETTER_MAIN}` : `${colors.ODDS_BETTER_MAIN}`};
  border: 1px solid
    ${(props) => (props.small ? `transparent` : `${colors.ODDS_BETTER_MAIN}`)};
  background-color: ${(props) =>
    props.small ? `transparent` : `${colors.YELLOW_WARNING_LIGHT}`};
  font-style: ${(props) => (props.small ? `italic` : `normal`)};

  @media screen and (max-width: 768px) {
    flex-direction: column;
    font-size: 24px;
  }
`;

const IconWarning = styled(Icon)`
  margin-right: ${(props) => (props.small ? `3px` : `10px`)};
  min-width: ${(props) => (props.small ? `9px` : `24px`)};
  min-height: ${(props) => (props.small ? `9px` : `24px`)};

  @media screen and (max-width: 768px) {
    min-width: ${(props) => (props.small ? `18px` : `36px`)};
    min-height: ${(props) => (props.small ? `18px` : `36px`)};
  }
`;

export default RankingWarning;

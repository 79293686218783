import React, { Component, Fragment, useContext } from "react";
import styled from "styled-components";
// import Title from "./Title";

// components
import StreamPlayer from "../Matches/StreamPlayer";

// services
import { colors } from "../../config/styles";
import { FeatureFlagContext } from "../../contexts/FeatureFlagContext";
import Title from "./Title";

const FeaturedHomeStream = () => {
  const context = useContext(FeatureFlagContext);

  const flagValueTwitch = context.getFlagValue("FEATURED_STREAM");
  const flagValueYoutube = context.getFlagValue("FEATURED_STREAM_YOUTUBE");

  return (
    <FeaturedStreamContainer>
      {flagValueTwitch && (
        <>
          <StreamPlayer
            streamPlatform="Twitch"
            streamChannel={flagValueTwitch}
            width="724"
            height="434"
            autoplay
            mute
            noMargin
          ></StreamPlayer>
        </>
      )}

      {flagValueYoutube && (
        <>
          <StreamPlayer
            streamPlatform="YouTubeEmbed"
            streamChannel={flagValueYoutube}
            width="724"
            height="434"
            autoplay
            mute
            noMargin
          ></StreamPlayer>
        </>
      )}
    </FeaturedStreamContainer>
  );
};

const FeaturedStreamContainer = styled.div`
  // border: 3px solid ${colors.LIVE};
  margin-top: 5px;

  // @media screen and (max-width: 768px) {
  //   width: 100%;
  //   left: 0px;
  // }
`;

export default FeaturedHomeStream;

import React, { useEffect, useState } from "react";
import Link from "next/link";
import styled from "styled-components";
import Card from "../Shared/Card";
import { colors } from "../../config/styles";
import { isLive } from "../../util/match-status";
import RouteGenerator from "../../util/route-generator";
import TeamLogo from "../Shared/TeamLogo";
import dayjs from "dayjs";
import LiveOddsBox from "../LiveOddsModal/LiveOddsBox";
import LiveOddsService from "../../services/live-odds";
import Icon from "../Shared/Icon";
import { S3_BASE_URL } from "../../config/constants";

const FeaturedMatchCard = ({
  match,
  className,
  onClick,
  isSelected,
  odds = [],
}) => {
  const odd = LiveOddsService.findOdd(match, odds);

  const matchIsLive = isLive(match.matchDate);

  const renderScore = (teamKey, scores, seriesScore, IsMatchFinished) => {
    if (!IsMatchFinished) {
      return "";
    }
    if (scores.length === 1) {
      return scores[0][`score${teamKey}`];
    }
    return seriesScore;
  };

  return (
    <>
      {!match.isVzone && (
        <Link
          prefetch={false}
          passHref
          href="/partida/[id]"
          as={RouteGenerator.getMatchRoute(match)}
          className={className}
        >
          <MatchCard
            onClick={(e) => {
              if (onClick) {
                e.preventDefault();
                onClick(match);
              }
            }}
          >
            <StyledCard
              isOver={!!match.isOver}
              isLive={matchIsLive}
              isSelected={isSelected}
            >
              <Tournament isLive={matchIsLive} isOver={!!match.isOver}>
                <TournamentName>
                  {match.tournament.tournamentName}
                </TournamentName>
                <TimeStatus IsMatchFinished={matchIsLive || match.isOver}>
                  {/* TBA */}
                  {match.isTBA === 1 && "TBA"}

                  {/* Finished */}
                  {!!match.isOver && !match.isPlayByPlay && "Finalizado"}

                  {/* Live */}
                  {matchIsLive &&
                    !match.isOver &&
                    !match.isPlayByPlay &&
                    "Ao vivo"}

                  {!!match.isPlayByPlay && !match.isOver && (
                    <PlayByPlayLabel>Tempo real</PlayByPlayLabel>
                  )}
                  {!!match.isPlayByPlay && !!match.isOver && (
                    <PlayByPlayLabelFinished>
                      Reveja os lances
                    </PlayByPlayLabelFinished>
                  )}

                  {/* Before Live */}
                  {!matchIsLive &&
                    !match.isTBA &&
                    !match.isOver &&
                    !match.isPlayByPlay &&
                    dayjs(match.matchDate, "X").format("DD/MM - HH:mm")}
                </TimeStatus>
              </Tournament>
              <Team>
                <TeamLogo teamId={match.teamA.teamId} size={15} />
                <span>{match.teamA.teamName}</span>
                {renderScore(
                  "A",
                  match.scores,
                  match.seriesScoreA,
                  matchIsLive || match.isOver
                )}
                <LiveOddsBox
                  odd={odd}
                  teamA={match.teamA}
                  teamB={match.teamB}
                  teamKey={"teamA"}
                />
              </Team>
              <Team>
                <TeamLogo teamId={match.teamB.teamId} size={15} />
                <span>{match.teamB.teamName}</span>
                {renderScore(
                  "B",
                  match.scores,
                  match.seriesScoreB,
                  matchIsLive || match.isOver
                )}
                <LiveOddsBox
                  odd={odd}
                  teamA={match.teamA}
                  teamB={match.teamB}
                  teamKey={"teamB"}
                />
              </Team>
            </StyledCard>
          </MatchCard>
        </Link>
      )}
      {!!match.isVzone && (
        <a
          prefetch={false}
          passHref
          // href="/partida/[id]"
          target="_blank"
          as={RouteGenerator.getMatchRoute(match)}
          href={RouteGenerator.getMatchRoute(match)}
          className={className}
        >
          <MatchCard
            onClick={(e) => {
              if (onClick) {
                e.preventDefault();
                onClick(match);
              }
            }}
          >
            <StyledCardVzone
              isOver={!!match.isOver}
              isLive={matchIsLive}
              isSelected={isSelected}
            >
              <TournamentVzone isLive={matchIsLive} isOver={!!match.isOver}>
                <TournamentNameVzone>
                  <span>
                    {match.tournamentVzone}

                    {/* <CtaVzone>- Central do VAL no BR</CtaVzone> */}
                    <RedirectIconTournamentVZone size={8} name="redirect" />
                  </span>
                </TournamentNameVzone>
                <TimeStatusVzone IsMatchFinished={matchIsLive || match.isOver}>
                  {/* TBA */}
                  {match.isTBA === 1 && "TBA"}

                  {/* Finished */}
                  {!!match.isOver && "Finalizado"}

                  {/* Live */}
                  {matchIsLive && !match.isOver && "Ao vivo"}

                  {/* {match.matchDate} */}

                  {/* Before Live */}
                  {!matchIsLive &&
                    !match.isTBA &&
                    !match.isOver &&
                    dayjs(match.matchDate, "X").format("DD/MM - HH:mm")}
                </TimeStatusVzone>
              </TournamentVzone>
              <TeamVzone>
                <TeamLogo teamId={match.teamA.teamId} size={15} />
                <span>{match.teamA.teamName}</span>
              </TeamVzone>
              <TeamVzone>
                <TeamLogo teamId={match.teamB.teamId} size={15} />
                <span>{match.teamB.teamName}</span>
              </TeamVzone>

              <VzoneLogo>
                <StreamIconVZone size={36} name="vzone" />
              </VzoneLogo>
            </StyledCardVzone>
          </MatchCard>
        </a>
      )}
    </>
  );
};

export default FeaturedMatchCard;

const MatchCard = styled.a`
  margin-right: 12px;

  @media screen and (max-width: 768px) {
    margin-right: 24px;
  }
`;

const Tournament = styled.div`
  font-size: 10px;
  min-width: 200px;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid ${colors.GRAY_LIGHTER};

  display: flex;
  justify-content: space-between;

  span {
    flex: 1;
    text-align: right;

    color: ${(props) => {
      if (props.isOver) return colors.FINISHED;
      if (props.isLive) return colors.LIVE;

      return "inherit";
    }};
  }
`;
const TournamentVzone = styled.div`
  font-size: 10px;
  min-width: 200px;
  width: 100%;
  padding: 8px;
  // background-color: ${colors.VZONE_ACCENT_LIGHT};

  background-color: ${(props) => {
    if (props.isOver) return colors.FINISHED_LIGHT;
    if (props.isLive) return colors.LIVE_BRIGHTER;

    return colors.VZONE_ACCENT_LIGHT;
  }};

  border-radius: 2px 2px 0 0;

  display: flex;
  justify-content: space-between;

  span {
    flex: 1;
    text-align: right;

    color: ${(props) => {
      if (props.isOver) return colors.FINISHED;
      if (props.isLive) return colors.LIVE;

      return `${colors.VZONE_DARK_LIGHT}`;
    }};
  }
`;

const TournamentName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.GRAY};
  flex-basis: 60%;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const TournamentNameVzone = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.GRAY};
  display: flex;
  flex-basis: 75%;

  span {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const Team = styled.div`
  font-size: 11px;
  min-width: 200px;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid ${colors.GRAY_LIGHTER};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  color: ${colors.GRAY};

  &:last-child {
    border-bottom: 0px;
  }

  span {
    flex: 1;
    font-weight: bold;
  }

  img {
    margin-right: 6px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    height: auto;
    white-space: nowrap;

    img {
      width: 50px;
      height: 50px;
      max-height: 24px;
      max-width: 24px;
    }
  }
`;
const TeamVzone = styled.div`
  font-size: 11px;
  min-width: 200px;
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  color: ${colors.GRAY};

  span {
    flex: 1;
    font-weight: bold;
  }

  img {
    margin-right: 6px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    height: auto;
    white-space: nowrap;

    img {
      width: 50px;
      height: 50px;
      max-height: 24px;
      max-width: 24px;
    }
  }
`;

const TimeStatus = styled.span`
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    white-space: nowrap;
  }
`;
const TimeStatusVzone = styled.span`
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    white-space: nowrap;
  }
`;

const StyledCard = styled(Card)`
  padding: 0px;
  border-radius: 2px;
  box-shadow: 0px 0px 0px 2px
    ${(props) => {
      if (props.isOver) return colors.FINISHED;
      if (props.isLive) return colors.LIVE;

      return "transparent";
    }};
`;

const StyledCardVzone = styled(Card)`
  font-style: italic;
  padding: 0px;
  border-radius: 2px;
  box-shadow: 0px 0px 0px 2px
    ${(props) => {
      if (props.isOver) return colors.FINISHED;
      if (props.isLive) return colors.LIVE;

      return "transparent";
    }};

  background-color: ${colors.VZONE_LIGHTEST};
  background-image: url("${S3_BASE_URL}/assets/vzone/matchcard-bg.png");
  background-repeat: no-repeat;
  background-position: 80px -2px;
  background-size: 200px;

  @media screen and (max-width: 768px) {
    background-position: 170px 60px;
    background-size: 200px;
  }
`;

const RedirectIconTournamentVZone = styled(Icon)`
  fill: ${colors.VZONE_ACCENT};
  margin-right: 4px;
  margin-left: 4px;

  @media screen and (max-width: 768px) {
    width: 19px;
    height: 19px;
    display: block;
  }
`;

const VzoneLogo = styled.div`
  // color: ${colors.GRAY_DARK};
  float: right;
  margin-top: -50px;

  @media screen and (max-width: 768px) {
    margin-top: -80px;
  }
`;

const StreamIconVZone = styled(Icon)`
  fill: ${colors.VZONE_ACCENT};
  margin-right: 4px;

  @media screen and (max-width: 768px) {
    width: 64px;
    height: 64px;
  }
`;

const CtaVzone = styled.div`
  font-size: 10px;
  margin-left: 2px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-left: 3px;
  }
`;

const PlayByPlayLabel = styled.span`
  background: ${colors.LIVE};
  font-size: 8px;
  color: white !important;
  font-weight: bold;
  padding: 3px 4px;
  border-radius: 3px;
  // margin-top: 4px;
  display: inline-block;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 6px 8px;
  }
`;

const PlayByPlayLabelFinished = styled.span`
  background: ${colors.FINISHED};
  font-size: 8px;
  color: white !important;
  font-weight: bold;
  padding: 3px 4px;
  border-radius: 3px;
  // margin-top: 4px;
  display: inline-block;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 6px 8px;
  }
`;

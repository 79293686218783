import styled from "styled-components";
import NextImage from "next/image";

import { colors } from "../../../config/styles";

import Card from "../../Shared/Card";

export const Container = styled(Card)``;

export const TabsContainer = styled(Card)`
  display: flex;
  padding: 0;
`;

export const Tab = styled.button`
  display: block;

  cursor: pointer;

  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;

  padding: 16px 16px 12px 16px;
  border: none;
  border-bottom: 4px solid ${(props) => props.active ? colors.ACCENT : "transparent"};

  background: ${(props) => props.active ? colors.GRAY_LIGHTEST : "transparent"};
  color: ${(props) => (props.active ? colors.GRAY_DARK : colors.GRAY_LIGHT)};

  transition: all 0.4s;

  &:hover {
    background: ${colors.GRAY_LIGHTEST};
    color: ${colors.GRAY_DARK};
    border-bottom: 4px solid ${colors.ACCENT};
  }
`;

export const NewsContainer = styled.div`
  padding: 16px;
`;

export const News = styled.a`
  display: flex;
  align-items: top;

  img {
    width: 71px;
    height: 53px;
    margin-right: 12px;
  }

  div {
    flex: 1;

    h3 {
      margin: 0;
      font-size: 13px;
      color: ${colors.GRAY_DARK};
    }

    small {
      font-size: 11px;
      color: ${colors.GRAY};
    }
  }

  & + * {
    margin-top: 0.75rem;
  }
`;
const StreamPlayer = (props) => {
  const getStreamUrl = (
    streamName,
    streamPlatform,
    streamChannel,
    autoplay,
    mute
  ) => {
    const autoplayString = autoplay ? "1" : "0";

    switch (streamPlatform) {
      case "Twitch":
        return `https://player.twitch.tv/?channel=${streamChannel}&parent=draft5.gg&parent=localhost&autoplay=${autoplayString}${
          mute ? "&mute=1" : ""
        }`;

      case "YouTubeGaming":
        return `https://gaming.youtube.com/embed/live_stream?channel=${streamChannel}&autoplay=${autoplayString}${
          mute ? "&mute=1" : ""
        }`;

      case "YouTubeEmbed":
        return `https://www.youtube.com/embed/${streamChannel}?autoplay=${autoplayString}${
          mute ? "&mute=1" : ""
        }`;

      case "FacebookLive":
        return `https://www.facebook.com/plugins/video.php?href=https://www.facebook.com/${streamName}/videos/${streamChannel}/?autoplay=${autoplay}`;

      case "Smashcast":
        return `http://www.smashcast.tv/embed/${streamChannel}?autoplay=${autoplayString}`;

      case "Mixer":
        return `https://mixer.com/embed/player/${streamChannel}?autoplay=${autoplayString}`;

      case "Kick":
        return `https://player.kick.com/${streamChannel}?autoplay=${autoplayString}`;

      default:
        return "";
    }
  };

  const {
    className,
    streamName,
    streamPlatform,
    streamChannel,
    width,
    height,
    autoplay,
    mute,
    noMargin,
    isAmp,
  } = props;

  if (isAmp) {
    const streamUrl = getStreamUrl(
      streamName,
      streamPlatform,
      streamChannel,
      autoplay,
      mute
    );

    return (
      <div
        style={{
          margin: noMargin ? "0px" : "24px",
          padding: noMargin ? "0px" : "24px",
        }}
      >
        {streamUrl ? (
          <amp-iframe
            layout="responsive"
            src={streamUrl}
            sandbox="allow-scripts allow-same-origin"
            height={height}
            width={width}
          >
            <amp-img
              src="https://static.draft5.gg/assets/placeholder.png"
              layout="fill"
              placeholder
            />
          </amp-iframe>
        ) : (
          <amp-img
            src="https://static.draft5.gg/assets/placeholder.png"
            layout="fill"
            placeholder
            height={height}
            width={width}
          />
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        margin: noMargin ? "0px" : "24px",
        padding: noMargin ? "0px" : "24px",
      }}
    >
      <iframe
        className={className || ""}
        title={streamName}
        src={getStreamUrl(
          streamName,
          streamPlatform,
          streamChannel,
          autoplay,
          mute
        )}
        height={height}
        width={width}
        frameBorder="0"
        scrolling="no"
        allowFullScreen
      />
    </div>
  );
};

export default StreamPlayer;

import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../config/styles";
import NewsService from "../../../services/news";
import Card from "../../Shared/Card";
import PopularNewsList from "../PopularNewsList";

const PopularNewsSelector = (props) => {
  const [newsList, setNewsList] = useState(props.initialData);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const trackTabChange = (range) => {
    window.dataLayer.push({
      event: "PopularNewsTabChange",
      range,
    });
  };

  const onTabChange = (range, tabIndex) => {
    loadNews(range);
    trackTabChange(range);
    setActiveTabIndex(tabIndex);
  };

  const loadNews = (range) => {
    setIsLoading(true);
    setError(null);

    NewsService.getPopularNews(range).then((response) => {
      setNewsList(response.data.data);
      setIsLoading(false);
    });
  };

  return (
    <Card noPadding>
      <TabsContainer>
        <Tab
          active={activeTabIndex === 0}
          onClick={() => {
            onTabChange(NewsService.RANGES.MONTH, 0);
          }}
        >
          Do Mês
        </Tab>
        <Tab
          active={activeTabIndex === 1}
          onClick={() => {
            onTabChange(NewsService.RANGES.WEEK, 1);
          }}
        >
          Da Semana
        </Tab>
        <Tab
          active={activeTabIndex === 2}
          onClick={() => {
            onTabChange(NewsService.RANGES.DAY, 2);
          }}
        >
          Do Dia
        </Tab>
      </TabsContainer>

      <PopularNewsListContainer>
        <PopularNewsList data={newsList} isLoading={isLoading} error={error} />
      </PopularNewsListContainer>
    </Card>
  );
};

const Tab = styled.a`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 16px 16px 12px 16px;
  display: block;
  cursor: pointer;
  transition: all 0.4s;

  background: ${(props) =>
    props.active ? colors.GRAY_LIGHTEST : "transparent"};

  color: ${(props) => (props.active ? colors.GRAY_DARK : colors.GRAY_LIGHT)};

  border-bottom: ${(props) =>
    props.active ? `4px solid ${colors.ACCENT}` : "4px solid transparent"};

  &:hover {
    background: ${colors.GRAY_LIGHTEST};
    color: ${colors.GRAY_DARK};
    border-bottom: 4px solid ${colors.ACCENT};
  }
`;

const TabsContainer = styled(Card)`
  display: flex;
  padding: 0;
`;

const PopularNewsListContainer = styled.div`
  padding: 16px;
`;

export default PopularNewsSelector;

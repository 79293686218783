import axios from "axios";
import { API_BASE_URL } from "../config/constants";

export default class RankingService {
  static getRankingEditions(page, amount) {
    const url = `${API_BASE_URL}/rankings`;

    return axios.get(url, { params: { page, amount } });
  }

  static getRankingDetails() {
    const url = `${API_BASE_URL}/ranking-valve/regions`;

    return axios.get(url);
  }

  static async getRankingByRegion(region, date) {
    // console.log("Getting ranking by region:", region, "and date:", date);
    const url = `${API_BASE_URL}/ranking-valve/region/${region}/${date}`;
    // console.log(url);

    return axios.get(url);
  }

  static getRankingDetail(rankingId) {
    const url = `${API_BASE_URL}/rankings/${rankingId}`;

    return axios.get(url);
  }
}

import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../../config/styles";

class Switch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOn: false,
    };
  }

  toggleSwitchState = () => {
    const { isOn } = this.state;
    const { onChange } = this.props;

    this.setState(
      {
        isOn: !isOn,
      },
      () => {
        if (onChange) onChange(this.state.isOn);
      }
    );
  };

  render() {
    const { isOn } = this.state;

    const { label, small, className } = this.props;

    return (
      <SwitchContainer className={className || ""}>
        <SwitchItem
          htmlFor="id"
          isOn={isOn}
          small={small}
          onKeyPress={this.toggleSwitchState}
          onClick={this.toggleSwitchState}
        >
          <Slider isOn={isOn} small={small} />
        </SwitchItem>

        <Label isOn={isOn}>{label}</Label>
      </SwitchContainer>
    );
  }
}

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: -5px;
`;

const SwitchItem = styled.div`
  height: ${(props) => (props.small ? "20px" : "20px")};
  width: ${(props) => (props.small ? "30px" : "40px")};
  border-radius: 40px;
  background: ${(props) => (props.isOn ? colors.ACCENT : colors.GRAY_LIGHT)};
  padding: 4px;
  cursor: pointer;
  transition: all 0.05s;
  position: relative;
`;

const Slider = styled.span`
  background: white;
  height: ${(props) => (props.small ? "12px" : "12px")};
  width: ${(props) => (props.small ? "12px" : "12px")};
  border-radius: 30px;
  display: block;
  position: absolute;
  left: ${(props) => {
    if (props.isOn && props.small) return "calc(100% - 16px)";
    if (props.isOn && !props.small) return "calc(100% - 16px)";

    return "4px";
  }};
  transition: all 0.05s;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
`;

const Label = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-left: ${(props) => (props.small ? "6px" : "8px")};
  color: ${(props) => (props.isOn ? colors.GRAY_DARK : colors.GRAY_LIGHT)};
  transition: all 0.05s;

  & svg {
    fill: ${(props) => (props.isOn ? colors.GRAY_DARK : colors.GRAY_LIGHT)};
    position: relative;
    top: 2px;
  }
`;

export default Switch;

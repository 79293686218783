import dayjs from "dayjs";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { S3_BASE_URL } from "../../../config/constants";
import { colors } from "../../../config/styles";
import RouteGenerator from "../../../util/route-generator";

const PopularNewsCard = (props) => {
  const trackClick = () => {
    window.dataLayer.push({
      event: "PopularNewsClick",
      slug: props.slug,
    });
  };

  return (
    <Link
      href="/noticia/[slug]"
      as={RouteGenerator.getNewsRoute(props.slug)}
      passHref
    >
      <PopularNewsCardContainer
        target="_blank"
        rel="noreferrer noopener"
        onClick={trackClick}
      >
        <PopularNewsImage image={props.image} />
        <PopularNewsData>
          <h3>{props.title}</h3>
          <small>{dayjs(props.date).format("DD [de] MMMM [de] YYYY")}</small>
        </PopularNewsData>
      </PopularNewsCardContainer>
    </Link>
  );
};

const PopularNewsCardContainer = styled.a`
  display: flex;
  align-items: top;
`;

const PopularNewsData = styled.div`
  flex: 1;

  h3 {
    margin: 0;
    font-size: 13px;
    color: ${colors.GRAY_DARK};
  }

  small {
    font-size: 11px;
    color: ${colors.GRAY};
  }
`;

const PopularNewsImage = styled.div`
  background-image: url(${S3_BASE_URL}/${(props) => props.image});
  background-size: cover;
  background-position: center;
  height: 53px;
  flex-basis: 71px;
  margin-right: 12px;
`;

export default PopularNewsCard;
